import Head from 'next/head';

const Custom404 = () => (
  <>
    <header className="pb-4 pt-4 relative overflow-hidden">
      <Head>
        <title>qonto.caarl.fr</title>
      </Head>
      <div className="text-title">Qonto</div>
    </header>
    <div className="block mx-auto max-w-sm px-5 md:px-0">
      404 - Not Found / Page non trouvée
    </div>
  </>
);

export default Custom404;
